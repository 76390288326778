// Shadows (originally from mdl http://www.getmdl.io/)
$bmd-shadow-umbra-opacity: 0.2 !default;
$bmd-shadow-penumbra-opacity: 0.14 !default;
$bmd-shadow-ambient-opacity: 0.12 !default;

// Declare the following for reuse with both mixins and the bootstrap variables
$bmd-shadow-focus: 0 0 8px rgba($black, .18), 0 8px 16px rgba($black, .36);

$bmd-shadow-2dp: 0 2px 2px 0 rgba($black, $bmd-shadow-penumbra-opacity),
  0 3px 1px -2px rgba($black, $bmd-shadow-umbra-opacity),
  0 1px 5px 0 rgba($black, $bmd-shadow-ambient-opacity);

$bmd-shadow-3dp: 0 3px 4px 0 rgba($black, $bmd-shadow-penumbra-opacity),
  0 3px 3px -2px rgba($black, $bmd-shadow-umbra-opacity),
  0 1px 8px 0 rgba($black, $bmd-shadow-ambient-opacity);

$bmd-shadow-4dp: 0 4px 5px 0 rgba($black, $bmd-shadow-penumbra-opacity),
  0 1px 10px 0 rgba($black, $bmd-shadow-ambient-opacity),
  0 2px 4px -1px rgba($black, $bmd-shadow-umbra-opacity);

$bmd-shadow-6dp: 0 6px 10px 0 rgba($black, $bmd-shadow-penumbra-opacity),
  0 1px 18px 0 rgba($black, $bmd-shadow-ambient-opacity),
  0 3px 5px -1px rgba($black, $bmd-shadow-umbra-opacity);

$bmd-shadow-8dp: 0 8px 10px 1px rgba($black, $bmd-shadow-penumbra-opacity),
  0 3px 14px 2px rgba($black, $bmd-shadow-ambient-opacity),
  0 5px 5px -3px rgba($black, $bmd-shadow-umbra-opacity);

$bmd-shadow-16dp: 0 16px 24px 2px rgba($black, $bmd-shadow-penumbra-opacity),
  0 6px 30px 5px rgba($black, $bmd-shadow-ambient-opacity),
  0 8px 10px -5px rgba($black, $bmd-shadow-umbra-opacity);

$bmd-shadow-24dp: 0 9px 46px 8px rgba($black, $bmd-shadow-penumbra-opacity),
  0 11px 15px -7px rgba($black, $bmd-shadow-ambient-opacity),
  0 24px 38px 3px rgba($black, $bmd-shadow-umbra-opacity);
