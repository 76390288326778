// Dropdowns
//
// Dropdown menu container and contents.

//$dropdown-bg:                    #fff !default;
//$dropdown-border-color:          rgba(0,0,0,.15) !default;
//$dropdown-border-width:          $border-width !default;
//$dropdown-divider-bg:            #e5e5e5 !default;
$dropdown-box-shadow: $bmd-shadow-2dp !default; //0 6px 12px rgba(0,0,0,.175) !default;
//
//$dropdown-link-color:            $gray-dark !default;
//$dropdown-link-hover-color:      darken($gray-dark, 5%) !default;
//$dropdown-link-hover-bg:         #f5f5f5 !default;
//
//$dropdown-link-active-color:     $component-active-color !default;
//$dropdown-link-active-bg:        $component-active-bg !default;
//
//$dropdown-link-disabled-color:   $gray-light !default;
//
//$dropdown-header-color:          $gray-light !default;
