/*!

 =========================================================
 * Material Dashboard - v2.0.1
 =========================================================

 * Product Page: http://www.creative-tim.com/product/now-ui-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard/blob/master/LICENSE.md)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

 @import "core/variables";
 @import "core/mixins";
 @import "core/core-bootstrap";
 @import "core/reboot";

// Core Components
@import "core/layout";
@import "core/buttons";
@import "core/checkboxes";
@import "dashboard/cards";
@import "core/custom-forms";
@import "core/switches";
@import "core/radios";
@import "core/carousel";
@import "dashboard/forms";
@import "core/input-group";
@import "core/list-group";
@import "core/nav";
@import "core/images";
@import "dashboard/navbar";
@import "core/badges";
@import "dashboard/alerts";
@import "core/pagination";
@import "core/pills";
@import "core/info-areas";
@import "core/type";
@import "core/tabs";
@import "core/tooltip";
@import "core/popover";
@import "core/modal";
@import "core/dropdown";
@import "core/drawer";
@import "core/progress";
@import "core/togglebutton";
@import "core/ripples";
// @import "core/popups";
@import "dashboard/rtl";
@import "dashboard/sidebar-and-main-panel";
@import "dashboard/timeline";
@import "dashboard/fixed-plugin";
@import "dashboard/tables";
@import "dashboard/misc";
@import "dashboard/pages";

 // Components for PRO
 @import "pro/social-buttons";
 @import "pro/fileupload";
 @import "pro/tables";
 @import "pro/sections";
 @import "pro/media";
 @import "pro/sections/footers";

 //plugin css
 @import "plugins/plugin-nouislider";
 @import "plugins/animate";
 @import "plugins/sweetalert2";
 @import "plugins/datatables.net";
 @import "plugins/jquery.jvectormap";
 @import "plugins/plugin-datetime-picker";
 @import "plugins/fullcalendar";
 @import "plugins/wizard-card";
 @import "plugins/chartist";
 @import "plugins/select-bootstrap";
 @import "plugins/perfect-scrollbar";
 @import "plugins/plugin-tagsinput";


// example pages and sections
@import "pro/example-pages";
@import "pro/sections/headers";
@import "pro/sections/footers";


@import "dashboard/responsive";
