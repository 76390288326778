// case where behavior is responsive, or with a marker class
@mixin media-breakpoint-down-or($breakpoint, $name) {
  #{unquote($name)} {
    @content;
  }

  @include media-breakpoint-down($breakpoint) {
    @content;
  }
}

// case where behavior is responsive, or with a marker class
@mixin media-breakpoint-up-or($breakpoint, $name) {
  #{unquote($name)} {
    @content;
  }

  @include media-breakpoint-up($breakpoint) {
    @content;
  }
}

// Name of the previous breakpoint, or null
//
//    >> breakpoint-next(sm)
//    xs
//    >> breakpoint-next(sm, (xs: 0, sm: 544px, md: 768px))
//    xs
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md))
//    xs
@function breakpoint-previous($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n > 1, nth($breakpoint-names, $n - 1), null);
}
