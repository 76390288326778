@media all and (max-width: 991px) {
    [class*="navbar-expand-"] > .container,
    [class*="navbar-expand-"] > .container-fluid{
        padding-left: 15px;
        padding-right: 15px;
    }

    .navbar .navbar-collapse .navbar-nav > li.button-container{
        padding: 15px;
    }

    .carousel .card .card-body{
      max-width: 340px;
      margin: 0 auto;
      min-height: 400px;
    }

    .navbar-collapse{
        position: fixed;
        display: block;
        top: 0px;
        height: 100vh;
        width: 230px;
        right: 0;
        margin-right: 0 !important;
        z-index: 1032;
        visibility: visible;
        background-color: #999;
        overflow-y: visible;
        border-top: none;
        text-align: left;
        padding-right: 0;
        padding-left: 0;

        max-height: none !important;

        @include transform-translate-x(230px);
        @include transitions (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));

        &::after{
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            background-color: $white-color;
            display: block;
            content: "";
            z-index: 1;
        }

        .dropdown-toggle:after{
            position: absolute;
            right: 16px;
            margin-top: 8px;
        }

        .navbar-nav{
            position: relative;
            z-index: 3;

            .nav-item{
                .nav-link{
                   color: $black-color;
                   margin: 5px 15px;
                }

                &.button-container .nav-link{
                    margin: 15px;
                }

                &:after{
                    width: calc(100% - 30px);
                    content: "";
                    display: block;
                    height: 1px;
                    margin-left: 15px;
                    // background-color: #e5e5e5;
                }

                &:last-child {
                    &:after{
                        display: none;
                    }
                }
            }
        }

        .nav-open &{
            @include transform-translate-x(0px);
        }
    }

    .nav-open{
        .navbar-translate{
            @include transform-translate-x(-230px);
        }
    }

    .navbar{
        .navbar-translate{
            width: 100%;
            position: relative;
            display: flex;
            -ms-flex-pack: justify !important;
            justify-content: space-between !important;
            -ms-flex-align: center;
            align-items: center;
            @include transitions-property (transform, 0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));
        }

        .dropdown.show .dropdown-menu{
            display: block;
        }

        .dropdown .dropdown-menu{
            display: none;
        }

        .dropdown-menu{
            .dropdown-item{
                margin-left: 1.5rem;
                margin-right: 1.5rem;
            }
        }

        .dropdown.show .dropdown-menu,
        .dropdown .dropdown-menu{
            background-color: transparent;
            border: 0;
            padding-bottom: 15px;
            transition: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            transform: none !important;
            width: auto;
            margin-bottom: 15px;
            padding-top: 0;
            height: 300px;
            animation: none;
            opacity: 1;
            overflow-y: scroll;
        }
    }

    .navbar.navbar-transparent{
        .navbar-toggler{
            .navbar-toggler-icon{
                background-color: $white-color;
            }
        }
    }

    #bodyClick {
        height: 100%;
        width: 100%;
        position: fixed;
        opacity: 0;
        top: 0;
        left: auto;
        right: 230px;
        content: "";
        z-index: 1029;
        overflow-x: hidden;
    }

    // for demo
    #navbar .navbar-collapse,
    #navigation .navbar-collapse{
        display: none !important;
    }

}

@media all and (min-width: 991px) {
    .navbar .navbar-nav{
        align-items: center;

        .button-container{
            margin-left: 0.1875px;
        }
    }
}

@media screen and (max-width: 991px) {
  .presentation-page {
    .section-components {
      .components-macbook {
        max-width: 850px !important;
        max-height: 480px !important;
        margin-top: 12vh;
        left: -12px;
      }
      .coloured-card-img,
      .table-img {
        display: none;
      }
      .social-img {
        left: 47%;
        top: 37%;
      }
      .pin-btn-img {
        top: 54%;
      }
      .share-btn-img {
        top: 12%;
      }
      .coloured-card-btn-img {
        top: -2%;
        left: 65%;
      }
    }
    .section-content {
      .area-img {
        max-width: 130px;
        max-height: 170px;
      }
      .info-img {
        max-width: 170px;
        max-height: 120px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .presentation-page {
    .section-components {
      .components-macbook {
        max-width: 350px !important;
        max-height: 250px !important;
        margin-top: 12vh;
        left: -12px;
      }
      .coloured-card-img,
      .table-img {
        display: none;
      }
      .social-img {
        left: -7%;
        top: 37%;
      }
      .pin-btn-img {
        top: 54%;
      }
      .share-btn-img {
        top: 7%;
      }
      .coloured-card-btn-img {
        top: -2%;
      }
    }
  }

  .presentation-page,
  .index-page,
  .section-page{
    #cd-vertical-nav{
      display: none;
    }
  }

  .index-page{
    .cd-section{
      .tim-typo .tim-note{
        width: 60px;
      }
    }
  }
}

@media screen and (max-width: 400px){
  .pro-badge{
    top: 90px !important;
    right: 30px !important;
  }
  .cd-vertical-nav{
    display: none !important;
  }
}
