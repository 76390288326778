// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.
$state-success-text: $bmd-inverse !default;
$state-success-bg: $brand-success !default;

$state-info-text: $bmd-inverse !default;
$state-info-bg: $brand-info !default;

$state-warning-text: $bmd-inverse !default;
$state-warning-bg: $brand-warning !default;

$state-danger-text: $bmd-inverse !default;
$state-danger-bg: $brand-danger !default;

$state-rose-bg: $brand-rose !default;
