//
// Modals
// Material Design element Dialogs
// --------------------------------------------------
.modal-dialog {
  & .modal-content{
      @include shadow-z-5();
      border-radius: $border-radius-large;
      border: none;

      .card-signup{
          margin: 0;

          .modal-header{
              padding-top: 0;
          }
      }
  }
  // Modal header
  // Top section of the modal w/ title and dismiss
  .modal-header {
    border-bottom: none;
    padding-top:    24px;
    padding-right:  24px;
    padding-bottom: 0;
    padding-left:   24px;

    .modal-title{
      text-align: center;
      width: 100%;
    }

    .close{
      position: absolute;
      top: 15px;
      right: 20px;
    }
  }
  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modal-body {
    padding-top:    24px;
    padding-right:  24px;
    padding-bottom: 16px;
    padding-left:   24px;
  }
  // Footer (for actions)
  .modal-footer {
    border-top: none;
    padding: 24px;

    &.text-center{
        text-align: center;
    }

    button {
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: auto;
      &.pull-left {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: -5px;
      }
    }
  }
  .modal-body + .modal-footer {
    padding-top: 0;
  }
}
.modal-backdrop {
  background: rgba(0,0,0,0.3);
}

.modal{
    .modal-dialog{
        margin-top: 100px;

        &.modal-login{
            width: 360px;

            .modal-header{
                .close{
                    color: $white-color;
                    top: -14px;
                    right: 10px;
                    text-shadow: none;
                    position: relative;
                }

            }

            .modal-footer{
                padding-bottom: 0;
                padding-top: 0;
            }

            .modal-body{
                padding-left: 4px;
                padding-bottom: 0;
                padding-top: 0;
            }

            .card-signup{
                margin-bottom: 0;
            }
        }

        &.modal-signup{
            max-width: 900px;

            .info-horizontal{
                padding: 0px 0px 20px;
            }

            .modal-title{
                text-align: center;
                width: 100%;
            }

            .modal-footer{
                padding: 0 5px;
            }

            .modal-header{
                padding-top: 0;
            }

            .card-signup{
                padding: 40px 0;
                margin-bottom: 0;
            }

            .modal-body{
                padding-bottom: 0;
                padding-top: 0;
            }
        }
    }
    .modal-header .close{
        color: $gray-light;

        &:hover,
        &:focus{
            opacity: 1;
        }

        i{
            font-size: 16px;
        }
    }
}

.modal-notice {
    .instruction{
         margin-bottom: 25px;
    }
    .picture{
        max-width: 150px;
    }

    .modal-content{
        .btn-raised{
            margin-bottom: 15px;
        }
    }
}

.modal-small{
    width: 300px;
    margin: 0 auto;

    .modal-body{
        margin-top: 20px;
    }
}
